import React from 'react'
import { Link } from 'react-router-dom'
import { Data } from '../scripts/data'
import { Helmet } from 'react-helmet'
import Principal from'../components/Principal'

const info = "Mas información"

const Products = props => (
    <>
        <Helmet>
        <meta
          name="description"
          content="los servicios que ofrece intermachine developers para resolver lo que se va a necesitar."
        />
        <meta
          name="keywords"
          content="intermachine developers, intermachine desarrollo web, intermachine desarrollo multiplataforma, intemachine fotografía"
        />
        <meta
          name="og:title"
          property="og:title" content="intermachine productos"
        />
        <meta
          name="og:description"
          property="og:description"
          content="los servicios que ofrece intermachine developers para resolver lo que se va a necesitar."
        />
        <meta name="robots" content="index, follow" />
            <title>intermachine servicios</title>
        </Helmet>
        <main className="row center-cols center-align">
            <Principal title="Servicios del Intermachine Developers" />
            {
                Data.map((body, i) => (
                    <article className="col m4 s12" key={i}>
                        <section className="card sticky-action small grey lighten-5">
                            <section className="card-image waves-effect waves-block waves-light">
                                <img className="img-stick responsive-img activator" src={body.src} alt={body.alt} />
                            </section>
                            <section className="card-content">
                                <span className="card-title activator">{body.title}<i className="material-icons right">more_vert</i></span>
                                <p className="grey-text text-darken-4"><Link to="#">{info}</Link></p>
                            </section>
                            <section className="card-reveal">
                                <h2 className="card-title">{body.title}<i className="material-icons right">close</i></h2>
                                <p className="grey-text text-darken-4">{body.content}</p>
                            </section>
                        </section>
                    </article>
                ))
            }
        </main>
    </>
)

export default Products
import firebase from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyB1_74-WheE2xiyCZwch98op3g4q9z1x-I",
    authDomain: "intermachine-developers.firebaseapp.com",
    databaseURL: "https://intermachine-developers.firebaseio.com",
    projectId: "intermachine-developers",
    storageBucket: "intermachine-developers.appspot.com",
    messagingSenderId: "91359592431",
    appId: "1:91359592431:web:0910e6fdee63dccd0c801a"
  }

firebase.initializeApp(firebaseConfig)

export default firebase;


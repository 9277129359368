import React from 'react'
import {Helmet} from 'react-helmet'

//contact components
import Form from '../components/Form'
import ContactCard from '../components/ContactCard'
import Principal from '../components/Principal'

const Contact = props => {
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <meta
                    name="description"
                    content="pagina de contacto de intermachine developers para la solicitud de producto con el cliente."
                />
                <title>intermachine developers pagina de contacto</title>
            </Helmet>
            <main className="container">
                <Principal title="Pagina de Contacto" />
                <section className="row">
                    <article className="col l6">
                        {/*formulario*/}
                        <Form />
                    </article>
                    <article className="col l6">
                        {/*card about */}
                        <ContactCard />
                    </article>
                </section>
            </main>
        </>
    );
}

export default Contact;
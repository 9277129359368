//react imports
import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';

import { Navbar } from 'react-materialize';

//pages
import Home from '../views/Home';
import Contact from '../views/Contact';
import NotFound from '../views/Notfound';
import Products from '../views/Products';
import About from '../views/About';

//sidenav
function SidenavMenu() {

    return (
        <>

            <li>
                <div className="user-view">
                    <div className="background">
                        <img
                            id='sidenav-image'
                            src="https://firebasestorage.googleapis.com/v0/b/intermachine-developers.appspot.com/o/menu.jpg?alt=media&token=580bc028-3864-480d-968f-4d95045e8e91"
                            alt="background"
                        />
                    </div>
                    <span className="white-text name">Intermachine Developers</span>
                    <span className="white-text email">menu mobile</span>
                </div>
            </li>

            <li>
                <Link to="/" className="sidenav-close" >
                    <i className="material-icons">home</i> Home
                </Link>
            </li>

            <li>
                <Link to="/services" className="sidenav-close" >
                    <i className="material-icons">collections</i> Servicios
                </Link>
            </li>

            <li>
                <Link to="/about" className="sidenav-close" >
                    <i className="material-icons">info_outline</i> Acerca de
                </Link>
            </li>

            <li>
                <Link to="/contact" className="sidenav-close" >
                    <i className="material-icons">account_circle</i> Contacto
                </Link>
            </li>

        </>
    )
}

// brand component
const Brand = () => <header className="brand-logo">
    <img
        src="https://firebasestorage.googleapis.com/v0/b/intermachine-developers.appspot.com/o/intermachine.svg?alt=media&token=35798da5-e5e2-48e6-84d9-e16231a29905"
        alt="logo"
        className='brand-img'
    />
</header>

// main component
const NavBar = () => {

    return (
        <BrowserRouter>
            <Navbar
                brand={<Brand />}
                alignLinks="right"
                sidenav={<SidenavMenu />}
                className='blue lighten-3'
            >
                <Link to="/">Home</Link>
                <Link to="/services">Servicios</Link>
                <Link to="/about">Acerca de</Link>
                <Link to="/contact">Contacto</Link>
            </Navbar>

            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/services" component={Products} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route component={NotFound} />
            </Switch>

        </BrowserRouter>

    )
}

export default NavBar;
import React from 'react'
import { Helmet } from 'react-helmet'
import { AboutUs } from '../scripts/data'
import Principal from '../components/Principal'

import { Carousel } from 'react-materialize';

const About = props => (
    <>
        <Helmet>
        <meta
          name="description"
          content="información de la empresa como la historia, misión, visión y valores."
        />
        <meta
          name="keywords"
          content="intermachine developers, intermachine misión, intermachine historia, intemachine visión, acerca de intermachine"
        />
        <meta
          name="og:title"
          property="og:title" content="Acerca de Intermachine"
        />
        <meta
          name="og:description"
          property="og:description"
          content="información de la empresa como la historia, misión, visión y valores."
        />
        <meta name="robots" content="index, follow" />
        <title>Acerca de Intermachine</title>
      </Helmet>

        <main>
            <Principal title="Acerca de Intermachine Develepors" />
            <Carousel options={{ fullWidth: true, indicators: true }}>
            {
                AboutUs.map((body, i) => (
                    <article className={body.classes} key={i}>
                        <h2>{body.titulo}</h2>
                        {body.descripcion}
                    </article>
                ))
            }
            </Carousel>
        </main>

    </>
)

export default About
import React from 'react'

const ContactCard = () => (
    <div className="card">
        <div className="card-image">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3982.6582348179886!2d-76.47307558573729!3d3.4330896523000565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30a76fd51b396b%3A0x773fd8b64db0e0dd!2sCra.%2024q%20%2386-26%2C%20Cali%2C%20Valle%20del%20Cauca!5e0!3m2!1ses!2sco!4v1575510068580!5m2!1ses!2sco"
                className='maps'
                allowFullScreen
                title='google maps'
            />
        </div>
        <div className="card-content">
            <h2 className="card-title">intermachine developers</h2>
            <p className="card-stacked">
                desarrollo web, aplicaciones moviles multiplataforma y fotografia. <br />
                julian cordoba <b>Desarrollador multimedia</b>. <br/>
                correo: <a href="mailto:omegamatrix5351@gmail.com">omegamatrix5351@gmail.com</a> <br/>
            </p>
        </div>
    </div>
)

export default ContactCard
//react
import React, { useEffect, useState } from 'react';

//components
import NavBar from './components/NavBar';
import FloatButton from './components/Fab';
import Footer from './components/Footer';

//materialize and styles css
import 'materialize-css/dist/css/materialize.min.css'
import './styles/App.css'


const App = () => {

  const [load, setLoad] = useState(true);

  useEffect(() => {
    demoAsyncCall().then(() => setLoad(false));
  })

  if (load) { // if your component doesn't have to wait for an async action, remove this block 
    return null; // render null when app is not ready
  }

  return (
    <>
      <NavBar />
      <FloatButton />
      <Footer />
    </>
  )
}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

export default App;

import React from 'react'
import { Helmet } from 'react-helmet'

const Home = props => (
        <>
        <Helmet>
        <meta
          name="description"
          content="empresa de desarrollo web, aplicaciones multiplataforma y sesiones fotograficas en implementar licencias propietarias"
        />
        <meta
          name="keywords"
          content="intermachine developers, desarrollo web, aplicaciones multiplataforma, sesiones de fotos, intermachine Cali github intermachine"
        />
        <meta
          name="og:title"
          property="og:title" content="Intermachine Developers"
        />
        <meta
          name="og:description"
          property="og:description"
          content="creamos dinanismo para el usuario desarrollado sitios web, aplicaciones multiplataforma y fotografia"
        />
        <meta name="robots" content="index, follow" />
        <title>Intermachine Developers</title>
      </Helmet>
        <main className="card-panel grey lighten-2 jumbotronStyle">
            <header className="card-content">
                <h1>Intermachine Developers</h1>
            </header>
            {/* codigo basado en https://codepen.io/geoffgraham/pen/jrWwWM*/}
            <p id="typewriter">creamos dinamismo para el usuario. <br/> consiguiendo experiencias unicas con los clientes</p>
        </main>
        </>
)

export default Home
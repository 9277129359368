// importar react
import React from 'react'
import { Helmet } from 'react-helmet'

//css
import '../styles/notfound.css';

//svg
import Page404 from '../components/Page404';

const NotFound = props => {
    return (
        <>

            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <meta
                    name="description"
                    content="la pagina que solicito no existe en la pagina web error 404 no encotrado"
                />
                <title>page not found</title>
            </Helmet>

            <main id="container">
                <Page404 />
                <p className="message">Page not found</p>
            </main>

            {/* codigo original: https://codepen.io/adnenrebai/pen/KNqQJP usuario codepen adnen rebai */}

        </>
    );
}

export default NotFound;
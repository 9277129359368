import React from 'react'

const Footer = () => (
    <>
        <footer className="page-footer footer-copyright blue lighten-5 black-text footer">
            <div className="container">
                © 2019 intermachine developers
                telefono: +57 (2) 343-4575
                <a 
            className="black-text text-lighten-4 right"
            href="http://www.sena.edu.co/" 
            target="_blank"
            rel="noopener noreferrer"
            >
                Sitio de SENA
            </a>
            </div>
        </footer>
    </>
)

export default Footer
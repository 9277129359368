import React, { Component } from 'react';

import { toast } from 'materialize-css'

import firebase from "../scripts/firebaseAPI";

const success= 'enviado datos...';

export default class Form extends Component {

    //estados
    state = {
        nombres: '',
        phone: '',
        email: '',
        asunto: '',
        mensaje: ''
    }

    //funciones
    onSubmit = e => {
        e.preventDefault();
        const db = firebase.firestore();
        db.settings({
            timestampsInSnapshots: true
        });
        // eslint-disable-next-line
        const userRef = db.collection('users').add({
            nombres: this.state.nombres,
            phone: this.state.phone,
            email: this.state.email,
            asunto: this.state.asunto,
            mensaje: this.state.mensaje
        });
        this.setState({
            nombres: '',
            phone: '',
            email: '',
            asunto: '',
            mensaje: ''
        });
        toast({ html: success })
        console.warn('el cliente ',this.state.nombres,' esta de acuerdo con la ley de datos personales')
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    //render view
    render() {
        return (
            <section className="card">

                <h2 className="center-align card-title margin-title">Formulario</h2>

                <div className="card-content">

                    <form onSubmit={this.onSubmit}>

                        <fieldset className="row">

                            <div className="input-field col m6">

                                <label htmlFor="inputNombres4">Nombres</label>

                                <input
                                    type="text"
                                    className="form-control validate"
                                    id="inputNombres4"
                                    name="nombres"
                                    onChange={this.onChange}
                                    value={this.state.nombres}
                                    required
                                />
                                <span
                                    className="helper-text"
                                    data-error="por favor digite el nombre y apellido"
                                    data-success="right"
                                >
                                    Nombres y Apellidos</span>
                            </div>

                            <div className="input-field col m6">

                                <label htmlFor="inputPhone4">Phone</label>

                                <input
                                    type="tel"
                                    className="form-control validate"
                                    id="inputPhone4"
                                    name="phone"
                                    onChange={this.onChange}
                                    value={this.state.phone}
                                    required
                                />
                                <span
                                    className="helper-text"
                                    data-error="por favor digite el numero"
                                    data-success="right"
                                >
                                    numero de Celular</span>

                            </div>

                        </fieldset>

                        <fieldset className="row">

                            <div className="input-field col m6">

                                <label htmlFor="inputEmail4">Email</label>

                                <input
                                    type="email"
                                    className="form-control validate"
                                    id="inputEmail4"
                                    name="email"
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    required
                                />
                                <span
                                    className="helper-text"
                                    data-error="ingrese un correo valido"
                                    data-success="right"
                                >
                                    Email del cliente</span>


                            </div>

                            <div className="input-field col m6">

                                <label htmlFor="inputAsunto4">Asunto</label>

                                <input
                                    type="text"
                                    className="form-control validate"
                                    id="inputAsunto4"
                                    name="asunto"
                                    onChange={this.onChange}
                                    value={this.state.asunto}
                                    required
                                />
                                <span
                                    className="helper-text"
                                    data-error="este campo es obligatorio"
                                    data-success="right"
                                >
                                    Motivo</span>

                            </div>

                        </fieldset>

                        <fieldset className="row">

                            <div className="input-field col m12">

                                <label htmlFor="inputMensaje4">mensaje</label>

                                <textarea
                                    className="materialize-textarea validate"
                                    id="inputMensaje4"
                                    name="mensaje"
                                    onChange={this.onChange}
                                    value={this.state.mensaje}
                                    required
                                />
                                <span
                                    className="helper-text"
                                    data-error="el mensaje no puede estar vacio"
                                    data-success="right"
                                >
                                    Ingrese su Mensaje</span>

                            </div>

                        </fieldset>

                        <button type="submit" className="btn waves-effect btn-block light-blue">Enviar</button>

                    </form>

                </div>

            </section>
        );
    }

}
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button, Icon } from 'react-materialize';

import {
    faWhatsapp,
    faGithub,
    faFacebook,
    faYoutube,
    faSlack
} from '@fortawesome/free-brands-svg-icons'

const FloatButton = () => (
    <Button
    floating
    fab={{ direction: 'top', hoverEnabled: false }}
    className="black"
    large
    icon={<Icon>pages</Icon>}
>

    <Button 
    floating 
    className=" whatsapp" 
    node="a" 
    href="https://wa.me/573107230981" 
    target="_blank"
    rel="noopener noreferrer"
    >
    <FontAwesomeIcon icon={faWhatsapp} size="lg" />
    </Button>

    <Button 
    floating 
    className="github"
    node="a" 
    href="https://github.com/intermachine-developers" 
    target="_blank"
    rel="noopener noreferrer"
    >
    <FontAwesomeIcon icon={faGithub} size="lg" />
    </Button>

    <Button 
    floating 
    className="facebook"
    node="a" 
    href="https://www.facebook.com/juliomixtreria/" 
    target="_blank"
    rel="noopener noreferrer"
    >
    <FontAwesomeIcon icon={faFacebook} size="lg" />
    </Button>

    <Button 
    floating 
    className="youtube"
    node="a" 
    href="https://youtube.com/omega5300" 
    target="_blank"
    rel="noopener noreferrer"
    >
    <FontAwesomeIcon icon={faYoutube} size="lg" />
    </Button>

    <Button 
    floating 
    className="blue"
    node="a" 
    href="https://stackshare.io/intermachine-developers/intermachine-developers" 
    target="_blank"
    rel="noopener noreferrer"
    icon={<Icon>share</Icon>}
    />
    
    <Button 
    floating 
    className="slack"
    node="a" 
    href="https://intermachined-r171337.slack.com" 
    target="_blank"
    rel="noopener noreferrer"
    >
    <FontAwesomeIcon icon={faSlack} size="lg" />
    </Button>

</Button>
        )

export default FloatButton
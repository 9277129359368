import React from 'react'

export const Data = [
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/intermachine-developers.appspot.com/o/Web.png?alt=media&token=1d34e25b-3a8c-4f19-9483-d9125479af6a',
        alt: 'web programming',
        title: 'desarrollo web',
        content: `
        desarrollo web usando python con django, nodejs con express, php con laravel, 
        desarrollo frontend usado webpack o gulp 
        (gulp es un ejecutor de tareas de javascript y php un lenguaje de lado de servidor 
            se implementara browsersync un servidor de desarrollo para observar los cambios),
            wordpress y frameworks o librerias basados en componetes.
            se implementa las bases de datos mysql o mongodb
        `
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/intermachine-developers.appspot.com/o/Sessions.jpg?alt=media&token=f42f5297-4ba8-446e-a257-1b16cd4205d4',
        alt: 'photography sessions',
        title: 'sessiones fotográficas',
        content: `
        desarrollo de sesiones fotográficas usando tecnicas como light painting, ambiental, bokeh, multiexposición (camara o digital), experimental, monocromatico y fitros (nativos de la camara como digitales).
        usamos software como Photoshop, lightroom, Gimp, darktable y como opcional Affinity photo.
        para bautizos, primera comunión, 15 años, matrimonio y publicitaria.
        `
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/intermachine-developers.appspot.com/o/CrossPlatform.jpg?alt=media&token=fec4c33d-2e6d-4e74-a6e4-158e6ebce345',
        alt: 'cross platform programming',
        title: 'desarrollo multiplataforma',
        content: `
        desarrollo multiplaforma usando Ionic, Xamarin, Flutter y React Native.
        ellos se puede implentar api's en cualquier lenguaje de backend y base de datos
        dipositivos: Android, dispositivos Apple (Ios) y PWA (aplicaciónes web progresivas)
        `
    }
]

export const AboutUs = [
    {
        titulo: 'Historia',
        classes: 'caption center-align gradient1',
        descripcion:
            <p className="center-align">
                sobre una empresa enfocada al desarrollo de sitios o aplicaciones para web, moviles multiplataforma y sesiones fotograficas.
                el nombre proviene de las palabras en ingles inter-machine siginifica entremaquinas y developers de desarrolladores.
                usando nuevos conceptos del desarrollo web moderno usando servidores de desarrollo,
                moviles usando tecnologia web y la entrega de la fotos puede en impreso o digital
                innovamos con una photobook o album digital an formato aplicacion usando react native o ionic.
        </p>

    },
    {
        titulo: 'Misión',
        classes: 'caption center-align gradient2',
        descripcion:
            <p className="center-align">
                Proveer servicios de desarrollo de aplicaciones o sitios web implementando un stack único y original.
                También sesiones fotográficas únicas y especiales para recordar, creando experiencias innovadoras con el cliente.
        </p>

    },
    {
        titulo: 'Visión',
        classes: 'caption center-align gradient3',
        descripcion:
            <p className="center-align">
                En tres años Seremos la primera empresa implementar el stack de tecnologías basado en el sitio web de StackShare
                para el desarrollo de sitio y aplicaciones multiplataforma, y el uso de sesiones de fotos creativas utilizando técnicas
                y álbum fotográficos con opción para publicar en las redes sociales y la creación de álbumes tipo aplicación usando
                frameworks de desarrollo multiplataforma fotográficas en Cali.
            </p>

    },
    {
        titulo: 'Valores',
        classes: 'caption center-align gradient4 white-text',
        descripcion:
            <ul className="center-align" >
                <li>pasion.</li>
                <li>honestidad.</li>
                <li>Confianza.</li>
                <li>respeto.</li>
                <li>creatividad.</li>
                <li>mente abierta.</li>
                <li>motivacion.</li>
            </ul>

    }
]